<template>
  <div class="elv-maintenance-container">
    <div class="elv-maintenance-content">
      <img src="@/assets/img/maintenance-elven-logo.png" alt="logo" />
      <h2>Elven is undergoing maintenance</h2>
      <p>Please wait for some time before accessing it.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import { useStorage } from '@vueuse/core'
import { versionCheckUpdate } from '@/lib/updateVersion'

const localVersion = useStorage('UPDATE_VERSION', localStorage.getItem('UPDATE_VERSION'))

const getLocalVersion = (event: StorageEvent) => {
  if (event.key === 'UPDATE_VERSION') {
    localVersion.value = event.newValue
  }
}

const setLocalVersion = (version: string) => {
  localVersion.value = version
}

emitter.on('setLocalVersion', setLocalVersion)

watch(
  localVersion,
  (val) => {
    if (val) {
      versionCheckUpdate()
    }
  },
  { immediate: true, deep: true }
)

onMounted(() => {
  // 监听本地存储的变化
  window.addEventListener('storage', getLocalVersion)
})

onBeforeUnmount(() => {
  emitter.off('setLocalVersion', setLocalVersion)
  window.removeEventListener('storage', getLocalVersion)
})
</script>

<style lang="scss" scoped>
.elv-maintenance-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #fafafa;

  .elv-maintenance-content {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      display: block;
      width: 280px;
      height: 280px;
    }

    h2 {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;
      text-align: center;
      color: #0e0f11;
    }

    p {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #636b75;
      margin-top: 15px;
    }
  }
}
</style>
